import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  SearchInput,
  SelectInput,
  ReferenceInput,
  TextInput,
} from 'react-admin'

import { userExporter } from '../../helpers/userExporter.js'

const orgFilters = [
  <SearchInput source="q" alwaysOn />,

  <ReferenceInput
    source="organization"
    reference="organizations"
    alwaysOn
    perPage={1000}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,

  <SelectInput
    source="device.platform"
    label="Platform"
    choices={[
      { id: 'iOS', name: 'iOS' },
      { id: 'Android', name: 'Android' },
    ]}
    alwaysOn
  />,

  <TextInput source="device.appVersion" label="App Version" alwaysOn style={{ width: 120 }} />,

  <TextInput source="device.model" label="Device Model" alwaysOn style={{ width: 140 }} />,
]

export const UserList = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={orgFilters}
      perPage={100}
      exporter={userExporter}
      {...props}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="firstName" sx={{ fontWeight: 'bold' }} />
        <TextField source="lastName" sx={{ fontWeight: 'bold' }} />

        <ReferenceField source="team" reference="teams">
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField source="organization" reference="organizations">
          <TextField source="name" />
        </ReferenceField>

        <DateField source="createdAt" locales="de-DE" />
        <DateField source="lastOnline" locales="de-DE" />

        <TextField source="device.platform" label="Platform" />
        <TextField source="device.osVersion" label="OS" />
        <TextField source="device.appVersion" label="App" />
        <TextField source="device.model" label="Model" />
      </Datagrid>
    </List>
  )
}