import * as React from "react";
import { AppBar } from "react-admin";
import { Typography } from "@mui/material";

import Logo from "./Logo";

const useStyles = () => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const CalimaAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar sx={{ color: "#000", backgroundColor: "yellow" }} {...props}>
      <Typography
        variant="h6"
        color="inherit"
        style={classes.title}
        id="react-admin-title"
      />
      <Logo />
      <span style={classes.spacer} />
    </AppBar>
  );
};

export default CalimaAppBar;
