import { Typography } from '@mui/material'
import { BooleanInput, FormDataConsumer, TextInput, NumberInput, email } from 'react-admin'

export const OrganizationFeaturesForm = () => {
  return (
    <>
      <Typography variant="h6">Auslöser</Typography>
      <BooleanInput source="features.bluetoothTrigger" label="Bluetooth Knopf" defaultValue={true} />
      <BooleanInput source="features.timeTrigger" label="Zeit-Alarm" defaultValue={true} />
      <BooleanInput source="features.motionTrigger" label="Unfallerkennung" defaultValue={true} />
      <br />

      <Typography variant="h6">Ortung</Typography>
      <BooleanInput source="features.siteManagement" label="Standortverwaltung" />
      <BooleanInput source="features.wifiPositioning" label="WiFi AccessPoints" />
      <BooleanInput source="features.beaconPositioning" label="Bluetooth Beacons" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.features?.beaconPositioning && <NumberInput source="positioningBeaconMajor" />
        }
      </FormDataConsumer>
      <br />

      <Typography variant="h6">Alarmierung</Typography>
      <BooleanInput source="features.connectCalls" label="Anrufer verbinden" defaultValue={false} />
      <br />

      <Typography variant="h6">Erforderliche Berechtigungen</Typography>
      <BooleanInput source="requiredPermissions.overrideMute" label="Stummschaltung aufheben" defaultValue={true} />
      <br />

      <Typography variant="h6">Cloud</Typography>
      <BooleanInput source="features.userActivity" label="Mitarbeiter-Aktivität" />
      <br />

      <Typography variant="h6">Unfallmeldung</Typography>
      <BooleanInput source="reporting.enabled" label="Aktiviert" />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.reporting?.enabled && <TextInput source="reporting.formLink" fullWidth />}
      </FormDataConsumer>
      <br />

      <Typography variant="h6">NSL</Typography>
      <TextInput source="alarmSenderEmail" validate={[email()]} />
    </>
  )
}
