import { Typography } from '@mui/material'

import {
  Edit,
  FormTab,
  TabbedForm,
  NumberInput,
  TextField,
  ArrayField,
  Datagrid,
  Toolbar,
  DeleteWithConfirmButton,
  SaveButton,
  CheckboxGroupInput,
  DateField,
  useRecordContext,
  useGetIdentity,
  NumberField,
} from 'react-admin'
import { OrganizationGeneralForm } from '../../components/forms/OrganizationGeneralForm'
import { OrganizationCommercialForm } from '../../components/forms/OrganizationCommercialForm'
import { OrganizationFeaturesForm } from '../../components/forms/OrganizationFeaturesForm'

const OrganizationEditActions = (props) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      {props.hide ? (
        <Typography variant="h7">Only the current deal owner can make changes to this organization.</Typography>
      ) : (
        <>
          <SaveButton disabled={props.disabled} />
          <DeleteWithConfirmButton undoable={false} disabled={props.disabled} />
        </>
      )}
    </Toolbar>
  )
}

const secondParser = (input) => input * 60
const secondFormatter = (record) => record / 60

const usageStyles = {
  display: 'inline-block',
  fontWeight: 'bold',
  fontSize: 20,
  paddingRight: 15,
  width: 140,
  textAlign: 'right',
}

export const OrganizationEdit = () => {
  return (
    <Edit>
      <OrganizationEditContent />
    </Edit>
  )
}

const OrganizationEditContent = () => {
  const record = useRecordContext()
  const { identity } = useGetIdentity()

  const isEmptyOrCurrentOwner = !record.owner || (identity && record.owner === identity.id)

  return (
    <TabbedForm style={{ position: 'relative' }} toolbar={<OrganizationEditActions hide={!isEmptyOrCurrentOwner} />}>
      <FormTab label="organization">
        <OrganizationGeneralForm />
      </FormTab>
      <FormTab label="commercial">
        <OrganizationCommercialForm />
      </FormTab>

      <FormTab label="admins">
        <ArrayField source="admins" fullWidth>
          <Datagrid bulkActionButtons={false}>
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="email" />
            <DateField source="lastLoginAt" showTime />
          </Datagrid>
        </ArrayField>
      </FormTab>

      <FormTab label="features">
        <OrganizationFeaturesForm />
      </FormTab>

      <FormTab label="config">
        <Typography variant="h6">Bluetooth Knopf</Typography>
        <NumberInput
          source="appConfig.bluetoothTrigger.preAlarmDuration"
          label="⚠️ Dauer Vor-Alarm [s]"
          defaultValue={15}
          min={5}
          max={300}
        />

        <Typography variant="h6">Zeit-Alarm</Typography>
        <NumberInput
          source="appConfig.timeTrigger.prePreAlarmDuration"
          label="ℹ️ Dauer Vor-Vor-Alarm [s]"
          defaultValue={30}
          min={15}
          max={600}
        />
        <NumberInput
          source="appConfig.timeTrigger.preAlarmDuration"
          label="⚠️ Dauer Vor-Alarm [s]"
          defaultValue={15}
          min={5}
          max={300}
        />
        <br />
        <div style={{ display: 'flex', gap: 10 }}>
          <NumberInput
            source="appConfig.timeTrigger.triggerDelayMin"
            label="Auslösung (Minimum) [m]"
            defaultValue={60 * 5}
            min={1}
            parse={secondParser}
            format={secondFormatter}
          />
          <NumberInput
            source="appConfig.timeTrigger.triggerDelayDefault"
            label="Auslösung (Standard) [m]"
            defaultValue={60 * 10}
            min={1}
            parse={secondParser}
            format={secondFormatter}
          />
          <NumberInput
            source="appConfig.timeTrigger.triggerDelayMax"
            label="Auslösung (Maximum) [m]"
            defaultValue={60 * 120}
            min={1}
            parse={secondParser}
            format={secondFormatter}
          />
        </div>

        <Typography variant="h6">Unfallerkennung</Typography>
        <NumberInput
          source="appConfig.motionTrigger.prePreAlarmDuration"
          label="ℹ️ Dauer Vor-Vor-Alarm [s]"
          defaultValue={30}
          min={15}
          max={180}
        />
        <NumberInput
          source="appConfig.motionTrigger.preAlarmDuration"
          label="⚠️ Dauer Vor-Alarm [s]"
          defaultValue={15}
          min={5}
          max={300}
        />
        <CheckboxGroupInput
          source="appConfig.motionTrigger.triggerTypes"
          label="Auslöser"
          choices={[
            { id: 'FALL', name: 'Sturz' },
            { id: 'MOTIONLESS', name: 'Bewegungslosigkeit' },
          ]}
          defaultValue={['FALL', 'MOTIONLESS']}
        />
        <div style={{ display: 'flex', gap: 10 }}>
          <NumberInput
            source="appConfig.motionTrigger.triggerDelayMin"
            label="Bewegungslos (Minimum) [m]"
            defaultValue={60 * 1}
            min={1}
            parse={secondParser}
            format={secondFormatter}
          />
          <NumberInput
            source="appConfig.motionTrigger.triggerDelayDefault"
            label="Bewegungslos (Standard) [m]"
            defaultValue={60 * 3}
            min={1}
            parse={secondParser}
            format={secondFormatter}
          />
          <NumberInput
            source="appConfig.motionTrigger.triggerDelayMax"
            label="Bewegungslos (Maximum) [m]"
            defaultValue={60 * 5}
            min={1}
            parse={secondParser}
            format={secondFormatter}
          />
        </div>
      </FormTab>

      <FormTab label="usage">
        <p>
          <TextField source="usage.incidents" style={usageStyles} />
          Notfälle
        </p>

        <p>
          <TextField source="usage.users" style={usageStyles} />
          Mitarbeiter
        </p>

        <p>
          <TextField source="usage.teams" style={usageStyles} />
          Teams
        </p>

        <p>
          <TextField source="usage.receivers" style={usageStyles} />
          Notfallkontakte
        </p>

        <p>
          <NumberField
            source="usage.communicationCosts"
            style={usageStyles}
            options={{
              style: 'currency',
              currency: 'EUR',
              showCurrency: true,
              minimumFractionDigits: 2,
            }}
          />
          Kommunikationskosten
        </p>
      </FormTab>
    </TabbedForm>
  )
}
