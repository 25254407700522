import jwt_decode from "jwt-decode";

export const STORAGE_KEY_ACCESS_TOKEN = "superadmin_accessToken";
export const STORAGE_KEY_IDENTITY = "superadmin_identity";

const authProvider = (apiUrl) => ({
  login: async ({ username, password }) => {
    const request = new Request(apiUrl + "/auth/login", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    const responseJSON = await response.json();
    if (responseJSON.error) {
      throw new Error(responseJSON.error);
    }

    localStorage.setItem(STORAGE_KEY_ACCESS_TOKEN, responseJSON.accessToken);
    localStorage.setItem(
      STORAGE_KEY_IDENTITY,
      JSON.stringify(responseJSON.superadmin)
    );
  },

  logout: () => {
    localStorage.clear();

    return Promise.resolve();
  },

  checkError: (error) => {
    const status = error.status;

    if (status === 401 || status === 403) {
      localStorage.clear();

      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: (params) => {
    const loggedIn = localStorage.getItem(STORAGE_KEY_ACCESS_TOKEN);

    // TODO: check if accessToken is expired

    if (loggedIn) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },

  getIdentity: () => {
    let admin;
    const { superadmin } = jwt_decode(localStorage.getItem(STORAGE_KEY_ACCESS_TOKEN))

    try {
      admin = JSON.parse(localStorage.getItem(STORAGE_KEY_IDENTITY));


      if (!admin || !superadmin) {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject(error);
    }

    const fullName = `${admin?.firstName} ${admin?.lastName}`;

    return Promise.resolve({
      fullName,
      id: superadmin,
    });
  },

  getPermissions: () => Promise.resolve(),
});

export default authProvider;
