import { Check } from '@mui/icons-material'

import {
  BooleanField,
  DateField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  Labeled,
  FunctionField,
  Datagrid,
  ReferenceManyField,
} from 'react-admin'

export const UserShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="firstName" />
      <TextField source="lastName" />

      <TextField source="phone" />

      <TextField source="team" />
      <TextField source="organization" />

      <DateField source="createdAt" showTime />
      <DateField source="lastOnline" showTime />
      <DateField source="lastHeartbeat" showTime />
      <DateField source="lastReminder" showTime />
      <DateField source="lastLoginAttempt" showTime />
      <DateField source="loginVerifiedAt" showTime />

      <TextField source="inviteMessage" />
      <TextField source="verificationMessage" />
      <TextField source="loginCode" />
      <NumberField source="failedVerificationAttempts" />

      <UserDevicesTable />
    </SimpleShowLayout>
  </Show>
)

export const UserDevicesTable = (props) => {
  return (
    <Labeled label={'Devices'} style={{ width: '100%' }}>
      <ReferenceManyField reference="devices" target="user">
        <Datagrid bulkActionButtons={false}>
          <FunctionField label="Name" render={(record) => record.name} />
          <FunctionField label="Modell" render={(record) => record.model} />
          <FunctionField label="Plattform" render={(record) => record.platform} />
          <FunctionField label="System" render={(record) => record.osVersion} />
          <FunctionField label="App" render={(record) => record.appVersion} />
          <DateField source="createdAt" />
          <DateField source="verifiedAt" />
          <DateField source="lastOnline" />

          <FunctionField
            label="Permissions"
            render={(record) => (
              <div>
                {record.permissions?.location && <span>🛰️</span>} &nbsp;
                {record.permissions?.notification && <span>🔔</span>} &nbsp;
                {record.permissions?.bluetooth && <span>🖲️</span>}
              </div>
            )}
          />

          <FunctionField
            label="Push"
            render={(record) => (
              <div title={record.push?.updatedAt}>
                {record.push?.token && (
                  <span>
                    <Check />
                  </span>
                )}
              </div>
            )}
          />

          <FunctionField
            label="Battery"
            render={(record) => (
              <div title={record.battery?.updatedAt}>
                {record.battery?.level && <span>{record.battery.level * 100}%</span>}
              </div>
            )}
          />
        </Datagrid>
      </ReferenceManyField>
    </Labeled>
  )
}
