import { useGetIdentity } from "react-admin";

export default function WelcomeMessage() {
  const { identity } = useGetIdentity();

  return (
    <div>
      <h1>Willkommen, {identity?.fullName} 🎉</h1>
    </div>
  );
}
