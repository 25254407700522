import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  CreateButton,
  NumberField,
  DateField,
  FunctionField,
  ReferenceField,
  BooleanField,
  SelectInput,
  ReferenceInput,
} from 'react-admin'

import { sanitizeListRestProps } from 'react-admin'

const ListActions = (props) => {
  return (
    <TopToolbar className={props.className} {...sanitizeListRestProps(props)}>
      <CreateButton />
    </TopToolbar>
  )
}

const messageFilters = [

  <SelectInput
    source="type"
    choices={[
      { id: 'push', name: 'Push' },
      { id: 'sms', name: 'SMS' },
      { id: 'voice', name: 'Voice' },
      { id: 'email', name: 'Email' },
    ]}
    alwaysOn
  />,

  <ReferenceInput source="organization" reference="organizations" alwaysOn>
    <SelectInput optionText="name" />
  </ReferenceInput>
]

export const MessageList = (props) => {
  // const classes = useStyles();

  return (
    <List sort={{ field: 'createdAt', order: 'DESC' }} actions={<ListActions />} filters={messageFilters} {...props}>
      <Datagrid bulkActionButtons={false}>
        <DateField source="createdAt" showTime />
        <FunctionField
          source="type"
          render={(record) => {
            switch (record.type) {
              case 'voice':
                return '📞'
              case 'sms':
                return '💬'
              case 'email':
                return '📧'
              case 'push':
                return '📲'
              default:
                return record.type
            }
          }}
        />
        <TextField source="from" />

        <FunctionField
          label="To"
          render={(record) => {
            if (record.type === 'push') {
              return <span title={record.to}>[{record.to.substring(0, 8) + '…'}]</span>
            }
            return record.to
          }}
        />

        <ReferenceField
          source="organization"
          reference="organizations"
          alwaysOn
          perPage={1000}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <TextField source="name" />
        </ReferenceField>

        <FunctionField
          label="Message"
          render={(record) =>
            record.message && (
              <button
                onClick={() => {
                  alert(record.message)
                }}
              >
                Read ({record.message?.length} chars)
              </button>
            )
          }
        />

        <TextField source="provider" />
        <BooleanField source="transmitted" />
        {/* <DateField source="answeredAt" showTime/> */}
        {/* <DateField source="endedAt" showTime /> */}
        <NumberField
          source="cost"
          options={{
            style: 'currency',
            currency: 'EUR',
            showCurrency: true,
            minimumFractionDigits: 3,
          }}
          locales="de-DE"
          emptyText="–"
          sortable={false}
        />
        <TextField source="status" />
      </Datagrid>
    </List>
  )
}
