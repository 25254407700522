import * as React from 'react'
import {
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  ImageInput,
  useDataProvider,
  useNotify,
  ImageField,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'

const maxFileSize = 1 * 1024 * 1024 // 1MB

export const OrganizationGeneralForm = () => {
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const { setValue } = useFormContext()
  const [isUploadingLogo, setIsUploadingLogo] = React.useState(false)

  const onLogoImageChange = async (file) => {
    // When react-admin passes no parameter
    if (!file) {
      setValue('logo', '')
      setIsUploadingLogo(false)
      notify('Bitte eine Bilddatei unter 1 MB auswählen.', { type: 'error' })
    }

    // When react-admin passes an Event object as parameter
    if (file && !file.size) {
      return
    }

    // When react-admin passes a File object as parameter
    if (file) {
      if (file.size > maxFileSize) {
        notify('Datei über 1MB groß!', { type: 'warning' })
        return
      }

      setValue('logo', '')
      setIsUploadingLogo(true)
      notify('Bild wird hochgeladen...', { type: 'info' })

      try {
        const response = await dataProvider.uploadImage(file)
        setValue('logo', response.data.url)
        notify('Bild erfolgreich hochgeladen.', { type: 'success' })
      } catch (error) {
        notify('ra.notification.http_error', { type: 'error' })
      } finally {
        setIsUploadingLogo(false)
      }
    }
  }

  return (
    <>
      <TextInput source="name" validate={[required()]} label="Unternehmsname" />
      <TextInput source="contactPerson.name" validate={[required()]} label="Ansprechpartner" />
      <ReferenceInput source="owner" reference="superadmins">
        <SelectInput optionText="email" label="Deal owner" />
      </ReferenceInput>

      <SelectInput
        source="language"
        choices={[
          { id: 'de', name: 'Deutsch' },
          { id: 'en', name: 'English' },
          { id: 'es', name: 'Español' },
        ]}
        validate={[required()]}
      />

      <ImageInput
        source="logoDnD"
        label="Upload Logo"
        accept="image/jpeg,image/gif,image/png"
        maxSize={1 * 1024 * 1024}
        onChange={onLogoImageChange}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      {isUploadingLogo && <div>uploading image...</div>}
      <TextInput source="logo" label="Logo URL" fullWidth disabled={isUploadingLogo} />
      <FormDataConsumer>
        {({ formData }) =>
          formData.logo && (
            <img
              src={formData.logo}
              alt="Logo"
              style={{
                position: 'absolute',
                top: 60,
                right: 10,
                maxHeight: 100,
              }}
            />
          )
        }
      </FormDataConsumer>
    </>
  )
}
