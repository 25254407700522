import {
  AutocompleteInput,
  Create,
  maxLength,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin'

export const MessageCreate = (props) => (
  <Create {...props}>
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton label="Send" />
        </Toolbar>
      }
    >
      <ReferenceInput
        source="organization"
        reference="organizations"
        validate={[required()]}
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput optionText="name" fullWidth />
      </ReferenceInput>

      <SelectInput
        source="type"
        label="Type"
        choices={[
          { id: 'push', name: 'Push' },
          { id: 'sms', name: 'SMS' },
          { id: 'call', name: 'Call' },
        ]}
        alwaysOn
        fullWidth
      />

      <TextInput source="message" label="Message" validate={[required(), maxLength(160)]} fullWidth />
    </SimpleForm>
  </Create>
)
