const API_URL = '/api/superadmin'

const API_URL_DEV = 'https://api.dev.calima.cloud/api/superadmin'
// const API_URL_DEV = "http://localhost:4000/api/superadmin";

// API endpoint selection
var apiEndpoint = API_URL
const { hostname } = window.location
const isDevServer = hostname.indexOf('dev.') !== -1 || hostname.indexOf('.dev') !== -1
const isDeployPreviewServer = hostname.indexOf('deploy-preview') !== -1
const isLocalServer = hostname.indexOf('localhost') !== -1
if (isDevServer || isLocalServer || isDeployPreviewServer) {
  apiEndpoint = API_URL_DEV
}

export { apiEndpoint }
