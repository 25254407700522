import { fetchUtils } from 'ra-core'
import jsonServerProvider from 'ra-data-json-server'
import { STORAGE_KEY_ACCESS_TOKEN } from './auth'

const calimaDataProvider = (apiUrl) => {
  // Setup HTTP client auth
  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' })
    }
    const accessToken = localStorage.getItem(STORAGE_KEY_ACCESS_TOKEN)
    options.headers.set('Authorization', `Bearer ${accessToken}`)
    return fetchUtils.fetchJson(url, options)
  }

  const defaultDataProvider = jsonServerProvider(apiUrl, httpClient)

  return {
    ...defaultDataProvider,
    getData: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}`).then(({ json }) => ({
        data: json,
      }))
    },
    uploadImage: (file) => {
      const formData = new FormData()
      formData.append('image', file)
      return httpClient(`${apiUrl}/media`, {
        method: 'POST',
        body: formData,
      }).then(({ json }) => ({
        data: {
          url: json.mediumImageUrl,
        },
      }))
    },
  }
}

export default calimaDataProvider
