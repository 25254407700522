import { SelectInput, DateInput, NumberInput, required, FormDataConsumer } from 'react-admin'

export const OrganizationCommercialForm = () => {
  return (
    <>
      <SelectInput
        source="contractType"
        choices={[
          { id: 'INTERNAL', name: 'Internal' },
          { id: 'PARTNER', name: 'Sales Partner' },
          { id: 'TEST', name: 'Testphase' },
          { id: 'PILOT', name: 'Pilot' },
          { id: 'CUSTOMER', name: 'Zahlender Kunde' },
        ]}
        validate={[required()]}
        label="Vertragstyp"
      />
      <DateInput source="serviceStart" defaultValue={new Date()} validate={[required()]} label="Vertragsbeginn" />
      <DateInput source="serviceEnd" label="Kündigungsdatum" />

      <div style={{ display: 'flex', gap: 10 }}>
        <NumberInput source="licenseCount" label="Anzahl Lizenzen" />
        <NumberInput source="monthlyPrice" label="mtl. Preis pro Lizenz [€]" style={{ width: 300 }} defaultValue={19} />

        <FormDataConsumer>
          {({ formData }) => <p>mtl. Preis gesamt: {formData.licenseCount * formData.monthlyPrice || 0} €</p>}
        </FormDataConsumer>
      </div>
      <div style={{ display: 'flex', gap: 10 }}>
        <NumberInput source="flexLicenseCount" label="Anzahl Flex-Lizenzen" />
        <NumberInput
          source="monthlyFlexPrice"
          label="mtl. Preis pro Flex-Lizenz [€]"
          style={{ width: 300 }}
          defaultValue={49}
        />

        <FormDataConsumer>
          {({ formData }) => <p>mtl. Preis gesamt: {formData.flexLicenseCount * formData.monthlyFlexPrice || 0} €</p>}
        </FormDataConsumer>
      </div>
      <div style={{ display: 'flex', gap: 10 }}>
        <NumberInput source="deviceLicenseCount" label="Anzahl Geräte-Lizenzen" />
        <NumberInput
          source="monthlyDevicePrice"
          label="mtl. Preis pro Geräte-Lizenz [€]"
          style={{ width: 300 }}
          defaultValue={9}
        />

        <FormDataConsumer>
          {({ formData }) => (
            <p>mtl. Preis gesamt: {formData.deviceLicenseCount * formData.monthlyDevicePrice || 0} €</p>
          )}
        </FormDataConsumer>
      </div>

      <FormDataConsumer>
        {({ formData }) => (
          <div>
            <p style={{ fontWeight: 'bold' }}>
              Gesamtpreis Lizenzen pro Monat:{' '}
              {(formData.licenseCount * formData.monthlyPrice || 0) +
                (formData.flexLicenseCount * formData.monthlyFlexPrice || 0) +
                (formData.deviceLicenseCount * formData.monthlyDevicePrice || 0)}{' '}
              €
            </p>
            <p style={{ fontWeight: 'bold' }}>
              Gesamtpreis Lizenzen pro Jahr:{' '}
              {((formData.licenseCount * formData.monthlyPrice || 0) +
                (formData.flexLicenseCount * formData.monthlyFlexPrice || 0) +
                (formData.deviceLicenseCount * formData.monthlyDevicePrice || 0)) *
                12}{' '}
              €
            </p>
          </div>
        )}
      </FormDataConsumer>
    </>
  )
}
