import Chart from "react-apexcharts";
import React, { useState, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { Card, CardContent } from "@mui/material";
import WelcomeMessage from "../components/general/WelcomeMessage";

export const Dashboard = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [organizationStats, setOrganizationStats] = useState();

  const [revenueStats, setRevenueStats] = useState();
  const [licenseStats, setLicenseStats] = useState();
  const [customerStats, setCustomerStats] = useState();

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        let result = await dataProvider.getData("statistics/organizations?start_date=2022-01-01");
        let stats = result?.data?.organizations;
        setOrganizationStats(result?.data?.organizations);

        if (stats) {
          let revenue = [],
            customers = [],
            licenses = [];

          stats.forEach((day) => {
            revenue.push({
              x: day.x,
              y: day.totalMonthlyRevenue,
            });
            setRevenueStats(revenue);

            licenses.push({
              x: day.x,
              y: day.totalLicenseCount,
            });
            setLicenseStats(licenses);

            customers.push({
              x: day.x,
              y: day.totalCount,
            });
            setCustomerStats(customers);
          });

          setRevenueStats(revenue);
        }
      } catch (error) {
        notify("ra.notification.http_error", { type: "error" });
      }
    };

    fetchChartData();
  }, [dataProvider, notify]);

  console.log({ organizationStats, revenueStats });

  return (
    <>
      <WelcomeMessage />

      <Card>
        <CardContent>
          <h2>MRR</h2>
          <Chart
            options={{
              chart: {
                id: "revenue-daily",
                zoom: { enabled: false },
                toolbar: {
                  show: false,
                },
              },
              xaxis: {
                type: "datetime",
              },
              yaxis: {
                min: 0,
                decimalsInFloat: 0,
              },
              plotOptions: {},
              tooltip: {
                enabled: false,
              },
              colors: ["#383e47"],
            }}
            series={[
              {
                data: revenueStats,
              },
            ]}
            type="area"
            height={300}
          />
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <h2>Licenses</h2>
          <Chart
            options={{
              chart: {
                id: "revenue-daily",
                zoom: { enabled: false },
                toolbar: {
                  show: false,
                },
              },
              xaxis: {
                type: "datetime",
              },
              yaxis: {
                min: 0,
                decimalsInFloat: 0,
              },
              plotOptions: {},
              tooltip: {
                enabled: false,
              },
              colors: ["#383e47"],
            }}
            series={[
              {
                data: licenseStats,
              },
            ]}
            type="area"
            height={300}
          />
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <h2>Customers</h2>
          <Chart
            options={{
              chart: {
                id: "revenue-daily",
                zoom: { enabled: false },
                toolbar: {
                  show: false,
                },
              },
              xaxis: {
                type: "datetime",
              },
              yaxis: {
                min: 0,
                decimalsInFloat: 0,
              },
              plotOptions: {},
              tooltip: {
                // enabled: false,
              },
              colors: ["#383e47"],
            }}
            series={[
              {
                data: customerStats,
              },
            ]}
            type="area"
            height={300}
          />
        </CardContent>
      </Card>
    </>
  );
};
