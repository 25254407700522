import { Typography } from '@mui/material'
import { Create, FormTab, TabbedForm, TextInput, required, email } from 'react-admin'
import { OrganizationGeneralForm } from '../../components/forms/OrganizationGeneralForm'
import { OrganizationCommercialForm } from '../../components/forms/OrganizationCommercialForm'
import { OrganizationFeaturesForm } from '../../components/forms/OrganizationFeaturesForm'

export const OrganizationCreate = (props) => (
  <Create {...props}>
    <TabbedForm style={{ position: 'relative' }}>
      <FormTab label="organization">
        <OrganizationGeneralForm />
      </FormTab>

      <FormTab label="commercial">
        <OrganizationCommercialForm />
      </FormTab>

      <FormTab label="admins">
        <Typography variant="h6">Admin</Typography>
        <TextInput source="firstAdmin.firstName" label="Vorname" validate={[required()]} />
        <TextInput source="firstAdmin.lastName" label="Nachname" validate={[required()]} />
        <TextInput source="firstAdmin.email" label="E-Mail" validate={[required(), email()]} />
      </FormTab>

      <FormTab label="features">
        <OrganizationFeaturesForm />
      </FormTab>
    </TabbedForm>
  </Create>
)
