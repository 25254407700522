import { downloadCSV } from 'react-admin'
import jsonExport from 'jsonexport/dist'

export const userExporter = async (users, fetchRelatedRecords) => {
  const organizations = await fetchRelatedRecords(
    users,
    'organization',
    'organizations'
  )
  const teams = await fetchRelatedRecords(users, 'team', 'teams')

  const usersForExport = users.map((user) => ({
    organization: organizations[user.organization]?.name || user.organization,
    team: teams[user.team].name || user.team,

    lastName: user.lastName,
    firstName: user.firstName,
    phone: user.phone,

    platform: user.device?.platform,
    osVersion: user.device?.osVersion,
    appVersion: user.device?.appVersion,
    model: user.device?.model,

    createdAt: user.createdAt,
    lastOnline: user.lastOnline,
    userState: user.userState,
  }))

  jsonExport(
    usersForExport,
    {
      // order fields in the export
      headers: ['organization', 'team', 'lastName', 'firstName', 'phone'],
    },
    (err, csv) => {
      downloadCSV(csv, 'calima_users') // download as 'calima_users.csv` file
    }
  )
}
