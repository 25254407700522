import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  CreateButton,
  ImageField,
  NumberField,
  DateField,
  FunctionField,
  ReferenceField,
  SearchInput,
  SelectInput,
  ReferenceInput,
} from 'react-admin'

import { sanitizeListRestProps } from 'react-admin'
import WarningIcon from '@mui/icons-material/Warning'

const ListActions = (props) => {
  return (
    <TopToolbar className={props.className} {...sanitizeListRestProps(props)}>
      <CreateButton />
    </TopToolbar>
  )
}

const licenseFieldRenderer = (record) => {
  const { users, licenseCount } = record
  let icon = ''

  if (licenseCount && licenseCount < users) {
    icon = <WarningIcon color="error" />
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {icon}
      <p style={{ paddingLeft: 5 }}>
        {users} / {licenseCount || '?'}
      </p>
    </div>
  )
}

const orgFilters = [
  <SearchInput source="q" alwaysOn />,

  <SelectInput
    source="contractType"
    choices={[
      { id: 'INTERNAL', name: 'Internal' },
      { id: 'PARTNER', name: 'Sales Partner' },
      { id: 'TEST', name: 'Testphase' },
      { id: 'PILOT', name: 'Pilot' },
      { id: 'CUSTOMER', name: 'Zahlender Kunde' },
    ]}
    alwaysOn
  />,

  <ReferenceInput source="owner" reference="superadmins" alwaysOn>
    <SelectInput optionText="email" />
  </ReferenceInput>,
]

export const OrganizationList = (props) => {
  // const classes = useStyles();

  return (
    <List sort={{ field: 'serviceStart', order: 'DESC' }} actions={<ListActions />} filters={orgFilters} {...props}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <ImageField
          source="logo"
          sx={{
            '& img': {
              maxHeight: 30,
              maxWidth: 60,
              objectFit: 'contain',
            },
          }}
        />
        <TextField source="name" sx={{ fontWeight: 'bold' }} />
        <TextField source="contactPerson.name" label="Contact Person" />

        <ReferenceField source="owner" reference="superadmins">
          <TextField source="email" />
        </ReferenceField>

        <TextField source="contractType" />

        <DateField source="serviceStart" locales="de-DE" />
        <DateField source="serviceEnd" locales="de-DE" emptyText="unlimited" />

        {/* <FunctionField
          label="NSL"
          render={(record) => !record.alarmSenderEmail || <CheckIcon />}
        /> */}

        <FunctionField label="Licenses" render={licenseFieldRenderer} sortBy="users" />

        <NumberField
          source="monthlyRevenue"
          options={{
            style: 'currency',
            currency: 'EUR',
            showCurrency: true,
          }}
          locales="de-DE"
          emptyText="–"
          label={'MRR'}
          sortable={false}
        />

        {/* <TextField source="adminName" /> */}
        {/* <TextField source="language" /> */}

        {/* <BooleanField
          source="features.bluetoothTrigger"
          label="Bluetooth Knopf"
        />
        <BooleanField source="features.timeTrigger" label="Zeit-Alarm" />
        <BooleanField
          source="features.motionTrigger"
          label="Unfall&shy;erkennung"
        /> */}

        {/* <NumberField source="positioningBeaconMajor" label="Beacon Major" />
        <BooleanField
          source="features.beaconPositioning"
          label="Beacon Ortung"
        /> */}

        {/* <BooleanField source="reporting.enabled" label="Reporting" /> */}
      </Datagrid>
    </List>
  )
}
