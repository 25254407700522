import './App.css'

import * as React from 'react'
import { Admin, Resource, Login } from 'react-admin'

import jwtAuthProvider from './providers/auth'
import calimaDataProvider from './providers/data'

import { OrganizationList } from './resources/organizations/OrganizationList.js'
import { OrganizationCreate } from './resources/organizations/OrganizationCreate.js'
import { OrganizationEdit } from './resources/organizations/OrganizationEdit.js'

import { UserList } from './resources/users/UserList.js'

import CalimaLayout from './components/general/Layout.js'
import OrganizationIcon from '@mui/icons-material/HeadsetMic'
import UserIcon from '@mui/icons-material/Person'
import MessageIcon from '@mui/icons-material/Message';

import { Dashboard } from './pages/Dashboard'
import { UserShow } from './resources/users/UserShow'

import { apiEndpoint } from './config'
import { MessageList } from './resources/messages/MessageList.js'
import { MessageCreate } from './resources/messages/MessageCreate.js'

// Setup providers
const authProvider = jwtAuthProvider(apiEndpoint)
const dataProvider = calimaDataProvider(apiEndpoint)

const SuperadminLoginPage = () => (
  <Login />
)

const App = () => (
  <Admin
    title="CALIMA Dashboard"
    dataProvider={dataProvider}
    authProvider={authProvider}
    layout={CalimaLayout}
    loginPage={SuperadminLoginPage}
    dashboard={Dashboard}
    disableTelemetry
  >
    <Resource
      name="organizations"
      icon={OrganizationIcon}
      list={OrganizationList}
      create={OrganizationCreate}
      edit={OrganizationEdit}
    />
    <Resource name="users" icon={UserIcon} list={UserList} show={UserShow} />
    <Resource name="messages" icon={MessageIcon} list={MessageList} create={MessageCreate} />
    <Resource name="superadmins" />
  </Admin>
)

export default App
